* {
    margin: 0;
    padding: 0;
}

body {
    background-color: black;
}

#logo {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 14px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif
}

#forecast {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
}
